import { connect } from "react-redux";
import translate from "../../../translates/translations";
import { Button } from "reactstrap";

function Car(props) {
  const { car } = props;
  const { item1, item2, item3, item4, item6, item7, item8, item5 } =
    translate.step1.card;

  return (
    <div className="s1-con-img">
      <div
        className="s1-bg"
        style={{
          backgroundImage: "url('/img/car-background.webp')",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img src={car.PictureURL} alt="" className="car-img" />
      </div>
      <div className="s1-description">
        <Button type="button" color="primary">
          Grupo {car.VehGroup} ({car.Code})
        </Button>
        <div className="d-flex margin-s1-name">
          <h5>{car.Features2.category}</h5>
          <p>{car.Name}</p>
        </div>
        <div className="s1-items">
          <div className="d-flex mb-2">
            <img src={"/icon/chevron-right.svg"} alt="" />
            <p className="m-0 p-0">
              {car.Features2.seats} {item1}
            </p>
          </div>
          <div className="d-flex mb-2">
            <img src={"/icon/chevron-right.svg"} alt="" />
            <p className="m-0 p-0">
              {car.Features2.largeSuitcase}{" "}
              {car.Features2.largeSuitcase == 1 ? item2 : item6}
            </p>
          </div>
          <div className="d-flex mb-2">
            <img src={"/icon/chevron-right.svg"} alt="" />
            <p className="m-0 p-0">
              {car.Features2.smallSuitcase}{" "}
              {car.Features2.smallSuitcase == 1 ? item3 : item7}
            </p>
          </div>
          <div className="d-flex mb-2">
            <img src={"/icon/chevron-right.svg"} alt="" />
            <p className="m-0 p-0">
              {car.Features2.doors} {item4}
            </p>
          </div>
          {car.TransmissionType == "Automatic" && (
            <div className="d-flex mb-2">
              <img src={"/icon/chevron-right.svg"} alt="" />
              <p className="m-0 p-0">{item5}</p>
            </div>
          )}
          {car.AirConditionInd && (
            <div className="d-flex mb-2">
              <img src={"/icon/chevron-right.svg"} alt="" />
              <p className="m-0 p-0">{item8}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang.code,
    place_pickup: state.home.place_pickup,
    place_dropoff: state.home.place_dropoff,
    info_user: state.home.info_user,
  };
};

export default connect(mapStateToProps)(Car);
