import React, { useState, useEffect } from 'react'; // eslint-disable-line
import translate from '../../translates/translations';
import { connect } from 'react-redux';
import CarSelected from '../components/molecules/carSelected';
import ClientType from '../components/organism/clientType';
import Price from '../components/organism/price';
import CardMap from '../components/molecules/cardMap';
import EquiOptional from '../components/molecules/equiOptional';
import StepProgress from '../components/molecules/stepProgress';
import { findCar, sumEquipment } from '../../services/api';
import Loader from '../components/atoms/loader';
import { totalCharge, totalTax, taxes } from '../../redux/actions/step2';
import { Container, Row } from 'reactstrap';
import { to } from '../../utils/redirect';
import { useHistory } from 'react-router-dom';
import { SwitchPrice } from '../components/molecules/SwitchPrice';
import { useSwitch } from '../../hooks/useSwitch';

function Step2(props) {
  const {
    info_user,
    car,
    dropoff,
    pickup,
    totalCharge,
    totalTax,
    set_prepaid,
    taxes,
    optionalEquipment,
  } = props;
  const { title1, title2 } = translate.step2.location;
  const history = useHistory();
  const { price, handleSwitch } = useSwitch();

  const [findAuto, setFindAuto] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  window.onbeforeunload = function (e) {
    return 'Texto de aviso';
  };

  const body = pickup && {
    pickup_location: pickup.Code,
    pickup_date: info_user.pickup_date,
    pickup_time: info_user.pickup_time,
    dropoff_location: dropoff.Code,
    dropoff_date: info_user.dropoff_date,
    dropoff_time: info_user.dropoff_time,
    language: 'pt',
    passenger_country_id: info_user.passenger_country_id,
    passenger_age: info_user.passenger_age,
    company: (car.companySelected == 2 || car.companySelected.toString().toLowerCase() === 'budget') ? 'Budget' : 'Avis',
    rate: car.ratesSelected.rate,
    vehicle_type: car.carSelected.VehType,
    vehicle_class: car.carSelected.VehClass,
    transmission_type: car.carSelected.TransmissionType,
    prepaid: set_prepaid,
    fuel_type: car.carSelected.FuelType ? car.carSelected.FuelType : '',
  };

  useEffect(() => {
    // console.log("api/findCar", body);
    findCar(body)
      .then((response) => response.data)
      .then((res) => {
        totalCharge(res.car.TotalCharge);
        totalTax(res.car.VehicleCharges.total_tax);
        taxes(res.car.VehicleCharges.taxes);
        setFindAuto(res);
      })
      .catch((error) => {
        history.push(to.error);
        console.log(error);
      });
  }, []);

  function setObj() {
    const equipment = {};
    optionalEquipment.map((x) => {
      if (x.CoverageType) {
        equipment[x.CoverageType] = x.Code;
      } else {
        equipment[x.id] = x.quantity ? x.quantity : 1;
      }
    });
    return equipment;
  }

  useEffect(() => {
    const equipment = setObj();
    sumEquipment({ ...body, equipment })
      .then((response) => response.data)
      .then((res) => {
        totalCharge(res.TotalCharge);
        totalTax(res.total_tax);
        taxes(res.taxes);
      })
      .catch((error) => console.log(error));
  }, [optionalEquipment]);

  if (findAuto) {
    return (
      <Container fluid className='m-0 p-0'>
        <Row className='d-flex justify-content-center align-items-center w-100 m-0'>
          <Container
            sm='12'
            md='10'
            lg='7'
            className='m-0 p-0 widthFixedDesktop'
          >
            <StepProgress valueOne={100} valueTwo={100} valueThree={0} />
            <div className='s2-container-column'>
              <div className='s2-con-left'>
                <CarSelected
                  car={car.carSelected}
                  company={body.company}
                  step={true}
                />
                <div className='s2-card-maps'>
                  <CardMap
                    location={pickup}
                    title={title1}
                    date={info_user.pickup_date_time}
                  />
                  <CardMap
                    location={dropoff}
                    title={title2}
                    date={info_user.dropoff_date_time}
                  />
                </div>
                <EquiOptional
                  car={findAuto.car.Equipment}
                  stateSwitch={price}
                />
                <ClientType
                  airlines={findAuto.airlines}
                  frequent={findAuto.frequent_flyer_program}
                  body={body}
                  equipment={setObj()}
                />
              </div>
              <div className='s2-con-right'>
                <SwitchPrice
                  text={translate.step2.switch}
                  onclick={handleSwitch}
                  state={localStorage.getItem('price')}
                />
                <Price
                  taxes={findAuto.car}
                  stateSwitch={price}
                  countryCode={findAuto.location.PickUpLocation.CountryCode}
                />
              </div>
            </div>
          </Container>
        </Row>
      </Container>
    );
  } else {
    return <Loader />;
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    info_user: state.home.info_user,
    pickup: state.step1.placeSelected.PickUpLocation,
    dropoff: state.step1.placeSelected.ReturnLocation,
    car: state.step1,
    set_prepaid: state.step1.prepaid,
    optionalEquipment: state.step2.optionalEquipment,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    totalCharge: (payload) => dispatch(totalCharge(payload)),
    totalTax: (payload) => dispatch(totalTax(payload)),
    taxes: (payload) => dispatch(taxes(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
